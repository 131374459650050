import { Component, OnDestroy } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { GlobalService } from './global.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {


  constructor(public global: GlobalService, public nav: NavController ,private menu: MenuController) {
  }

  ngOnDestroy() {
    console.log('Destroy the xxx service')
  }
  ionViewDidLoad() {
  }

  Guardar(item){
    console.log(item);
    this.nav.navigateRoot([item]);
  }

  Cerrar() {
    this.global.ISLOGIN = false;
    localStorage.clear();
    this.nav.navigateRoot('/login');

  }

}
