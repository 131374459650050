import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { VigilanteGuard } from './vigilante.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '*',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'monitoreo',
    loadChildren: () => import('./monitoreo/monitoreo.module').then( m => m.MonitoreoPageModule)
  },
  {
    path: 'mapa',
    loadChildren: () => import('./mapa/mapa.module').then( m => m.MapaPageModule)
  },
  {
    path: 'variables',
    loadChildren: () => import('./variables/variables.module').then( m => m.VariablesPageModule)
  },

  {
    path: 'adminmapas',
    loadChildren: () => import('./adminmapas/adminmapas.module').then( m => m.AdminmapasPageModule)
  },
  {
    path: 'visor',
    loadChildren: () => import('./visor/visor.module').then( m => m.VisorPageModule)
  },
  {
    path: 'newkml2',
    loadChildren: () => import('./newkml2/newkml2.module').then( m => m.Newkml2PageModule)
  },
  {
    path: 'adminusuarios',
    loadChildren: () => import('./adminusuarios/adminusuarios.module').then( m => m.AdminusuariosPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'usuarioedict',
    loadChildren: () => import('./usuarioedict/usuarioedict.module').then( m => m.UsuarioedictPageModule)
  },
  {
    path: 'adminproyecto',
    loadChildren: () => import('./adminproyecto/adminproyecto.module').then( m => m.AdminproyectoPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'newproyect',
    loadChildren: () => import('./newproyect/newproyect.module').then( m => m.NewproyectPageModule)
  },
  {
    path: 'proyectnew',
    loadChildren: () => import('./proyectnew/proyectnew.module').then( m => m.ProyectnewPageModule)
  },
  {
    path: 'adminaom',
    loadChildren: () => import('./adminaom/adminaom.module').then( m => m.AdminaomPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'calendario',
    loadChildren: () => import('./calendario/calendario.module').then( m => m.CalendarioPageModule),
    // canActivate:[VigilanteGuard]
  },
  {
    path: 'adminmetricas',
    loadChildren: () => import('./adminmetricas/adminmetricas.module').then( m => m.AdminmetricasPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'admincliente',
    loadChildren: () => import('./admincliente/admincliente.module').then( m => m.AdminclientePageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'galerianew',
    loadChildren: () => import('./galerianew/galerianew.module').then( m => m.GalerianewPageModule)
  },
  {
    path: 'documentosnew',
    loadChildren: () => import('./documentosnew/documentosnew.module').then( m => m.DocumentosnewPageModule)
  },
  {
    path: 'cards1',
    loadChildren: () => import('./cards1/cards1.module').then( m => m.Cards1PageModule)
  },
  {
    path: 'cards2',
    loadChildren: () => import('./cards2/cards2.module').then( m => m.Cards2PageModule)
  },
  {
    path: 'factgenerada',
    loadChildren: () => import('./factgenerada/factgenerada.module').then( m => m.FactgeneradaPageModule)
  },
  {
    path: 'factpagada',
    loadChildren: () => import('./factpagada/factpagada.module').then( m => m.FactpagadaPageModule)
  },
  {
    path: 'adminepc',
    loadChildren: () => import('./adminepc/adminepc.module').then( m => m.AdminepcPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'admininver',
    loadChildren: () => import('./admininver/admininver.module').then( m => m.AdmininverPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'adminepc',
    loadChildren: () => import('./adminepc/adminepc.module').then( m => m.AdminepcPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'editcliente',
    loadChildren: () => import('./editcliente/editcliente.module').then( m => m.EditclientePageModule)
  },
  {
    path: 'newclient',
    loadChildren: () => import('./newclient/newclient.module').then( m => m.NewclientPageModule)
  },
  {
    path: 'editepc',
    loadChildren: () => import('./editepc/editepc.module').then( m => m.EditepcPageModule)
  },
  {
    path: 'newepc',
    loadChildren: () => import('./newepc/newepc.module').then( m => m.NewepcPageModule)
  },
  {
    path: 'editinver',
    loadChildren: () => import('./editinver/editinver.module').then( m => m.EditinverPageModule)
  },
  {
    path: 'newinver',
    loadChildren: () => import('./newinver/newinver.module').then( m => m.NewinverPageModule)
  },
  {
    path: 'propuestas',
    loadChildren: () => import('./propuestas/propuestas.module').then( m => m.PropuestasPageModule),
    canActivate:[VigilanteGuard]
  },
  {
    path: 'newadd',
    loadChildren: () => import('./newadd/newadd.module').then( m => m.NewaddPageModule)
  },
  {
    path: 'q1',
    loadChildren: () => import('./q1/q1.module').then( m => m.Q1PageModule)
  },
  {
    path: 'q2',
    loadChildren: () => import('./q2/q2.module').then( m => m.Q2PageModule)
  },
  {
    path: 'q3',
    loadChildren: () => import('./q3/q3.module').then( m => m.Q3PageModule)
  },
  {
    path: 'q4',
    loadChildren: () => import('./q4/q4.module').then( m => m.Q4PageModule)
  },
  {
    path: 'simulador',
    loadChildren: () => import('./simulador/simulador.module').then( m => m.SimuladorPageModule)
  },
  {
    path: 'cliente',
    loadChildren: () => import('./cliente/cliente.module').then( m => m.ClientePageModule)
  },
  {
    path: 'new2add',
    loadChildren: () => import('./new2add/new2add.module').then( m => m.New2addPageModule)
  },
  {
    path: 'crear-visita',
    loadChildren: () => import('./crear-visita/crear-visita.module').then( m => m.CrearVisitaPageModule)
  },
  {
    path: 'crear-visita',
    loadChildren: () => import('./crear-visita/crear-visita.module').then( m => m.CrearVisitaPageModule)
  },
  {
    path: 'solicitud-documentos/:id',
    loadChildren: () => import('./solicitud-documentos/solicitud-documentos.module').then( m => m.SolicitudDocumentosPageModule)
  },
  {
    path: 'documentos-soli',
    loadChildren: () => import('./documentos-soli/documentos-soli.module').then( m => m.DocumentosSoliPageModule)
  },
  {
    path: 'ver-detalle',
    loadChildren: () => import('./ver-detalle/ver-detalle.module').then( m => m.VerDetallePageModule)
  },
  {
    path: 'terminar-manten',
    loadChildren: () => import('./terminar-manten/terminar-manten.module').then( m => m.TerminarMantenPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'ver-estruc',
    loadChildren: () => import('./ver-estruc/ver-estruc.module').then( m => m.VerEstrucPageModule)
  },
  {
    path: 'ver-oym',
    loadChildren: () => import('./ver-oym/ver-oym.module').then( m => m.VerOymPageModule)
  },
  {
    path: 'modi-vista',
    loadChildren: () => import('./modi-vista/modi-vista.module').then( m => m.ModiVistaPageModule)
  },
  {
    path: 'simulando',
    loadChildren: () => import('./simulando/simulando.module').then( m => m.SimulandoPageModule)
  },
  {
    path: 'construccion',
    loadChildren: () => import('./construccion/construccion.module').then( m => m.ConstruccionPageModule)
  },
  {
    path: 'c1',
    loadChildren: () => import('./c1/c1.module').then( m => m.C1PageModule)
  },
  {
    path: 'c2',
    loadChildren: () => import('./c2/c2.module').then( m => m.C2PageModule)
  },
  {
    path: 'c3',
    loadChildren: () => import('./c3/c3.module').then( m => m.C3PageModule)
  },
  {
    path: 'ver-constr',
    loadChildren: () => import('./ver-constr/ver-constr.module').then( m => m.VerConstrPageModule)
  },
  {
    path: 'calendario-new',
    loadChildren: () => import('./calendario-new/calendario-new.module').then( m => m.CalendarioNewPageModule)
  },
  {
    path: 'ver-visitas',
    loadChildren: () => import('./ver-visitas/ver-visitas.module').then( m => m.VerVisitasPageModule)
  },
  {
    path: 'ver-valores',
    loadChildren: () => import('./ver-valores/ver-valores.module').then( m => m.VerValoresPageModule)
  },
  {
    path: 'newiver',
    loadChildren: () => import('./newiver/newiver.module').then( m => m.NewiverPageModule)
  },
  {
    path: 'newperso',
    loadChildren: () => import('./newperso/newperso.module').then( m => m.NewpersoPageModule)
  },
  {
    path: 'cal-hitos',
    loadChildren: () => import('./cal-hitos/cal-hitos.module').then( m => m.CalHitosPageModule)
  },
  {
    path: 'edithito',
    loadChildren: () => import('./edithito/edithito.module').then( m => m.EdithitoPageModule)
  },
  {
    path: 'ver-om',
    loadChildren: () => import('./ver-om/ver-om.module').then( m => m.VerOmPageModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./filter/filter.module').then( m => m.FilterPageModule)
  },
  {
    path: 'alertaslist',
    loadChildren: () => import('./alertaslist/alertaslist.module').then( m => m.AlertaslistPageModule)
  },
  {
    path: 'editarchive',
    loadChildren: () => import('./editarchive/editarchive.module').then( m => m.EditarchivePageModule)
  },
  {
    path: 'edit-energy/:id',
    loadChildren: () => import('./edit-energy/edit-energy.module').then( m => m.EditEnergyPageModule)
  },









];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
