import { Injectable } from '@angular/core';
import { ajax } from 'jquery';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
// import { CalendarioComponent } from './calendario/calendario.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  proyectid1;
  proyectid2;
  Idperso;
  IdInver;
  idcliente;
  idMantenimiento2;
  idproyectoenergy;
  searchQueryconstr;
  viewPro = false;
  itemid = new Array();
  sudtitle;
  IdMantenimientoModificar: any;
  IdProyecGaleria;
  idMantenimiento;
  clienteid;
  dataTime;
  IdProyecDoc;
  ISLOGIN = false;
  FOLDER = 'Despacho'
  Visualizarmap;
  Id_Usuario;
  Id_Client;
  SelectID
  idRole;
  LAT;
  LOG;
  Construid;
  USERDATALIWA;
  datosproyecto;
  datosseccional;
  UserName;
  lastSession;
  CLIENTDATALIWA;
  Clientdata;
  itemid2;
  ids;
  color = "danger";
  iniciar = "Mantenimiento No iniciado";
  newproyect;
  SelectIDM;
  IdMan;
  istermi;
  latlog = new Array();
  mantenimi;
  inicio;
  newidmantenimiento;
  name = JSON.parse(localStorage.getItem('name'));
  public appPages = [
    { title: 'Login', url: '/login', icon: 'person-circle', children: [], open: false },
    // { title: 'calendario', url:'calendario', icon: 'person-circle', component: CalendarioComponent }
  ];
  Aulas = [];
  Aulas2 = [];
  Aulas3 = [];
  AULASEL = [];
  Popover;
  Popover1;
  Popovertwo;
  Modal;
  filtroactive = new Array();
  mapStyle: any[] = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
  ];
  mapStyle2: any[] = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8ec3b9"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1a3646"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#64779e"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#334e87"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#6f9ba5"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3C7680"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#304a7d"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2c6675"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#255763"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#b0d5ce"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "road.local",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3a4762"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#0e1626"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4e6d70"
        }
      ]
    }
  ];
  LOGIN;
  idmodificardoc;
  local;
  idproyectos;
  upiduser;
  btnfiltro;
  role;
  edithito;
  idmantenimiento;
  cliente = [];
  epcs = [];
  industria = [];
  tipoactivo = [];
  beneficios = [];
  operacion = [];
  fechai = null;
  fechaf = null;
  tammax = null;
  tammin = null;
  tirmax = null;
  tirmin = null;
  valormax = null;
  valormin = null;
  formatearNumeroColombia(numero) {
    const opciones = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    return numero.toLocaleString('es-CO', opciones);
  }
  formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  constructor(private route: Router, private http: HttpClient, public nav: NavController) {

  }

  Seccion() {
    this.LOGIN = JSON.parse(localStorage.getItem('Log'));
    if (this.LOGIN === true) {
      this.local = true;
      localStorage.setItem("local", JSON.stringify(this.local));
    }

    this.local = JSON.parse(localStorage.getItem('local'));
    this.role = JSON.parse(localStorage.getItem('rol'));
    if (this.local == true) {
      if (this.role == 1 || this.role == 2) { // Administradores
        this.ISLOGIN = true;
        this.appPages = [
          { title: 'Pagina principal', url: '/adminproyecto', icon: 'create', open: false, children: [] },
          {
            title: 'Gestión de información', url: 'false', icon: 'folder-open', open: false,
            children: [
              { title: 'Usuarios', url: '/adminusuarios', icon: 'accessibility' },
              { title: 'Cliente', url: '/admincliente', icon: 'people-circle' },
              { title: 'EPC', url: '/adminepc', icon: 'body' },
              { title: 'Inversionista', url: '/admininver', icon: 'pulse' },
              { title: 'Alertas', url: '/alertaslist', icon: 'alarm' },
            ]
          },
          {
            title: 'Calendarios', url: 'false', icon: 'folder-open', open: false,
            children: [
              { title: 'AOM', url: '/calendario-new', icon: 'construct' },
              { title: 'Hitos', url: '/cal-hitos', icon: 'hammer' },
            ]
          },
          { title: 'Propuestas', url: '/propuestas', icon: 'reader', open: false, children: [] },
          // { title: 'Construcción', url: '/construccion', icon: 'hammer', open: false, children: [] },
          { title: 'Simulador', url: '/simulador', icon: 'analytics', open: false, children: [] },
          { title: 'Dashboard', url: '/dashboard', icon: 'bar-chart', open: false, children: [] }

        ];
        // this.nav.navigateRoot(['/dashboard']);
      } else if (this.role == 3) {
        this.ISLOGIN = true;
        this.appPages = [
          { title: 'Pagina principal', url: '/adminproyecto', icon: 'create', open: false, children: [] },
          {
            title: 'Gestión de información', url: 'false', icon: 'folder-open', open: false,
            children: [
              // { title: 'Usuarios', url: '/adminusuarios', icon: 'accessibility' },
              // { title: 'Cliente', url: '/admincliente', icon: 'people-circle' },
              { title: 'EPC', url: '/adminepc', icon: 'body' },
              // { title: 'Inversionista', url: '/admininver', icon: 'pulse' },
            ]
          },
          {
            title: 'Calendarios', url: 'false', icon: 'folder-open', open: false,
            children: [
              { title: 'AOM', url: '/calendario-new', icon: 'construct' },
              { title: 'Hitos', url: '/cal-hitos', icon: 'hammer' },
            ]
          },
          // { title: 'Propuestas', url: '/propuestas', icon: 'reader', open: false, children: [] },
          // { title: 'Simulador', url: '/simulador', icon: 'analytics', open: false, children: [] },
          // { title: 'Dashboard', url: '/dashboard', icon: 'bar-chart', open: false, children: [] }

        ];
        // this.nav.navigateRoot(['/dashboard']);
      } else if (this.role == 4) {
        this.ISLOGIN = true;
        this.appPages = [
          { title: 'Pagina principal', url: '/adminproyecto', icon: 'create', open: false, children: [] },
          {
            title: 'Gestión de información', url: 'false', icon: 'folder-open', open: false,
            children: [
              // { title: 'Usuarios', url: '/adminusuarios', icon: 'accessibility' },
              // { title: 'AOM', url: '/calendario-new', icon: 'construct' },
              // { title: 'Cliente', url: '/admincliente', icon: 'people-circle' },
              // { title: 'EPC', url: '/adminepc', icon: 'body' },
              { title: 'Inversionista', url: '/admininver', icon: 'pulse' },
            ]
          },
          // { title: 'Propuestas', url: '/propuestas', icon: 'reader', open: false, children: [] },
          { title: 'Simulador', url: '/simulador', icon: 'analytics', open: false, children: [] },
          { title: 'Dashboard', url: '/dashboard', icon: 'bar-chart', open: false, children: [] }

        ];
        // this.nav.navigateRoot(['/dashboard']);
      } else if (this.role == 5) {
        this.ISLOGIN = true;
        this.appPages = [
          { title: 'Pagina principal', url: '/adminproyecto', icon: 'create', open: false, children: [] },
          {
            title: 'Gestión de información', url: 'false', icon: 'folder-open', open: false,
            children: [
              // { title: 'Usuarios', url: '/adminusuarios', icon: 'accessibility' },
              // { title: 'AOM', url: '/calendario-new', icon: 'construct' },
              { title: 'Cliente', url: '/admincliente', icon: 'people-circle' },
              // { title: 'EPC', url: '/adminepc', icon: 'body' },
              // { title: 'Inversionista', url: '/admininver', icon: 'pulse' },
            ]
          },
          { title: 'Propuestas', url: '/propuestas', icon: 'reader', open: false, children: [] },
          { title: 'Simulador', url: '/simulador', icon: 'analytics', open: false, children: [] },
          { title: 'Dashboard', url: '/dashboard', icon: 'bar-chart', open: false, children: [] }

        ];
        // this.nav.navigateRoot(['/dashboard']);
      } else if (this.role == 6) {
        this.ISLOGIN = true;
        this.appPages = [
          { title: 'Pagina principal', url: '/adminproyecto', icon: 'create', open: false, children: [] },
          {
            title: 'Gestión de información', url: 'false', icon: 'folder-open', open: false,
            children: [
              // { title: 'Usuarios', url: '/adminusuarios', icon: 'accessibility' },
              { title: 'Cliente', url: '/admincliente', icon: 'people-circle' },
              // { title: 'EPC', url: '/adminepc', icon: 'body' },
              // { title: 'Inversionista', url: '/admininver', icon: 'pulse' },
            ]
          },
          {
            title: 'Calendarios', url: 'false', icon: 'folder-open', open: false,
            children: [
              { title: 'AOM', url: '/calendario-new', icon: 'construct' },
              { title: 'Hitos', url: '/cal-hitos', icon: 'hammer' },
            ]
          },
          // { title: 'Propuestas', url: '/propuestas', icon: 'reader', open: false, children: [] },
          // { title: 'Construcción', url: '/construccion', icon: 'hammer', open: false, children: [] },
          { title: 'Simulador', url: '/simulador', icon: 'analytics', open: false, children: [] },
          { title: 'Dashboard', url: '/dashboard', icon: 'bar-chart', open: false, children: [] }


        ];
        // this.nav.navigateRoot(['/dashboard']);
      } else {
        this.ISLOGIN = false;
        this.appPages = [
          { title: 'Login', url: '/login', icon: 'person-circle', children: [], open: false },
        ];
      }

    } else {
      this.nav.navigateRoot(['/login']);
    }
  }

  consultar(datos, respuesta) {
    console.log('Consultando fetch');
    try {
      fetch('https://servicio-suncapital-yzotmrdvwq-uc.a.run.app/', {
        method: 'POST',
        body: JSON.stringify(datos),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (!response.ok) {
          throw new Error('La solicitud no se completó correctamente.');
        }
        return response.json();
      }).then((res) => {
        if (res === undefined || res == null) {
          throw new Error('La respuesta es undefined');
        }
        console.log(res);
        respuesta(null, res);
      }).catch((err) => {
        throw new Error('catch');
        console.log('mencontraste3', err);
      });

    } catch (err) {
      console.log(err)
    }
  }
  consultargmail(datos, respuesta) {
    console.log(JSON.stringify(datos), "llegando")
    try {
      fetch('https://servico-notificaciones-mail-yzotmrdvwq-uc.a.run.app', {
        method: 'POST',
        body: JSON.stringify(datos),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        console.log(response, "estetttt")
        respuesta(null, response)
      }).catch((err) => {
        respuesta(err)
        // console.log('mencontraste3')
      });

    } catch (err) {
      console.log(err)
    }

  }

  consultar_siigo(datos, respuesta) {
    try {
      fetch('https://api-siigo-gex-yzotmrdvwq-uc.a.run.app', {
        method: 'POST',
        body: JSON.stringify(datos),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json()).then((res) => {
        console.log(res)
        respuesta(null, res)
      }).catch((err) => {
        respuesta(err)
      });

    } catch (err) {
      console.log(err)
    }

  }
  //messageUrl = 'http://192.168.0.10:8001/';
  messageUrl = 'https://www.php.engenius.com.co/DatabaseLIWA.php';

  sendMessage(messageModel: any): Observable<any> {
    return this.http.post(this.messageUrl, messageModel);
  }
  initData() {
    const vari = localStorage.getItem('USERDATALIWA');
    if (vari != null) {
      this.USERDATALIWA = vari.split(',');
      console.log('USERDATALIWA', this.USERDATALIWA);
      this.UserName = this.USERDATALIWA[3];
      this.Id_Usuario = this.USERDATALIWA[0];
      this.lastSession = this.USERDATALIWA[19];
      this.idRole = this.USERDATALIWA[2];
      const client = localStorage.getItem('CLIENTDATALIWA');
      this.CLIENTDATALIWA = client.split(',');
      this.Clientdata = this.CLIENTDATALIWA;
      console.log('CLIENTDATALIWA', this.CLIENTDATALIWA);
      this.Id_Client = this.CLIENTDATALIWA[0];
      this.validSession();
    } else {
      this.route.navigateByUrl('/login');
    }

  }
  validSession() {
    let now = moment().format('YYYY-MM-DD');
    let last = this.lastSession;
    let dayNow = now.split('-')[2];
    let lastDay = last.split('-')[2];
    if (dayNow !== lastDay) {
      localStorage.clear();
      this.route.navigateByUrl('/login');
    }
  }

  momentFromNow(date: any) {
    moment.lang('es');
    var diff = moment(date).fromNow();
    return diff;
  }


  momentSencilla(date: any) {
    moment.lang('es');
    var diff = moment(date).fromNow();
    return diff;
  }
}
